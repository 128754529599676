import request from "@/utils/request";

export function getCircleList(params) {
  return request.post("/api/getCircleList", params);
}

export function createCircle(params) {
  return request.post("/api/createCircle", params);
}

export function editCircle(params) {
  return request.post("/api/editCircle", params);
}

export function getCircleTypeList(params) {
  return request.post("/api/getCircleTypeList", params);
}

export function createCircleType(params) {
  return request.post("/api/createCircleType", params);
}

export function modifyCircleType(params) {
  return request.post("/api/modifyCircleType", params);
}

export function getMessageList(params) {
  return request.post("/api/getMessageList", params);
}

export function createMessage(params) {
  return request.post("/api/createMessage", params);
}

export function editMessage(params) {
  return request.post("/api/editMessage", params);
}

export function delMessage(params) {
  return request.post("/api/delMessage", params);
}

export function queryUserByName(params) {
  return request.post("/api/queryUserByName", params);
}

export function queryPostList(params) {
  return request.post("/api/getPostList", params);
}

export function modifyPost(params) {
  return request.post("/api/modifyPost", params);
}
