const messageList = [
    {
        label: "id",
        prop: 'messageId',
        align: 'center',
    },
    {
        label: "内容",
        prop: 'messageContent',
        align: 'center',
    },
    {
        label: "类型",
        slot: 'messageType',
        align: 'center',
    },
    {
        label: "人员id",
        prop: 'userIds',
        align: 'center',
    },
    {
        label: "状态",
        slot: 'status',
    },
    {
        label: "创建日期",
        slot: 'createTime',
    },
    {
        label: '操作',
        slot: 'options'
    }
]

export default {
    messageList,
}