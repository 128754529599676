const postList = [
    {
        label: "id",
        prop: 'postId',
        width: 120,
        align: 'center',
    },
    {
        label: "标题",
        prop: 'circleTitle',
        align: 'center',
    },
    {
        label: "内容",
        slot: 'circleDes',
        align: 'center',
    },
    {
        label: "加精",
        slot: 'isRefinement',
        align: 'center',
    },
    {
        label: "状态",
        slot: 'status',
    },
    {
        label: "创建日期",
        slot: 'createTime',
    },
    {
        label: '操作',
        slot: 'options'
    }
]

export default {
    postList,
}