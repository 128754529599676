<template>
    <div class="businessList">
        <div class="derive">
            <el-button size="small" type="primary" @click="createSystem()">添加</el-button>
        </div>
        <base-table :columns="messageList" :data="tableData" :pagination="searchForm" :total="total" @getData="initData"
            :loading="loading" :isPaginationShow="true" wrapperHeight="calc(100% - 45px)">
            <el-table-column slot="messageType" label="类型" align="center" width="180">
                <template slot-scope="scope">
                    <div>{{ scope.row.messageType == 0 ? '全部' : '个人' }}</div>
                </template>
            </el-table-column>
            <el-table-column slot="status" label="状态" align="center" width="80">
                <template slot-scope="scope">
                    <div>{{ scope.row.status == 1 ? '可用' : '不可用' }}</div>
                </template>
            </el-table-column>
            <el-table-column slot="createTime" label="创建日期" align="center" width="180">
                <template slot-scope="scope">
                    <div>{{ convertGMTToLocal(scope.row.createTime) }}</div>
                </template>
            </el-table-column>
            <el-table-column slot="options" label="操作" align="center" width="180" fixed="right">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="editSystem(scope.row)">修改</el-button>
                    <el-button type="text" size="small" @click="delBusiness(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </base-table>
        <systemModel ref="systemModel"></systemModel>
    </div>
</template>
<script>
import columns from "./columns";
import { getMessageList, delMessage } from '@/api/car'
import systemModel from './system-modal.vue'
export default {
    components: {
        systemModel: systemModel
    },
    data() {
        return {
            ...columns,
            total: 0,
            loading: false,
            tableData: [],
            searchForm: {
                page: 1,
                pageCount: 10
            },
        }
    },
    mounted() {
        this.initData()
    },
    methods: {
        convertGMTToLocal(gmt) {
            const gmtDate = new Date(gmt)
            const bjOffset = 8 * 60
            const bjDate = new Date(gmtDate.getTime() + bjOffset * 60 * 1000)
            return bjDate.toISOString().split(',')[0].replace('T', ' ').replace('.000Z', '');
        },
        async initData() {
            const { code, data } = await getMessageList(this.searchForm)
            if (code == 200) {
                this.tableData = data.dataList
                this.total = data.count
            }
        },
        createSystem() {
            this.$refs.systemModel.isShow('new')
        },
        editSystem(info) {
            this.$refs.systemModel.isShow('edit', info)
        },
        async delBusiness(info) {
            const { code } = await delMessage(info)
            if (code == 200) {
                this.initData()
            }
        }
    },

}
</script>
<style scoped lang="scss">
.businessList {
    .derive {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
    }
}
</style>