import Layout from '@/Layout/index.vue'
export default {
  path: '/post',
  name: 'post',
  redirect: '/post/post-list',
  auth_key: "postManager",
  meta: { title: '帖子管理', hidden: false,requireAuth: true },
  component: Layout,
  children: [
    {
      path: 'post-list',
      name: 'post-list',
      auth_key: "h5UserList",
      meta: { title: '帖子列表', hidden: false,requireAuth: true },
      component: () => import('@/views/post/post-list.vue')
    },
   
  ]
}