import Layout from '@/Layout/index.vue'
export default {
  path: '/system',
  name: 'system',
  redirect: '/system/systemManager',
  auth_key: "systemManager",
  meta: { title: '系统消息', hidden: false,requireAuth: true },
  component: Layout,
  children: [
    {
      path: 'systemManager',
      name: 'systemManager',
      auth_key: "h5List",
      meta: { title: '公告列表', hidden: false,requireAuth: true },
      component: () => import('@/views/system/messageList.vue')
    },
  ]
}