const circleList = [
    {
        label: "id",
        prop: 'circleId',
        align: 'center',
    },
    {
        label: "名称",
        prop: 'circleName',
        align: 'center',
    },
    {
        label: "图标",
        slot: 'circleIcon',
    },
    {
        label: "车圈类型",
        prop: 'circleType',
        align: 'center',
    },
    {
        label: "公告",
        prop: 'circleTop',
        align: 'center',
        with: '180'
    },
    {
        label: "状态",
        slot: 'status',
    },
    {
        label: "创建日期",
        slot: 'createTime',
    },
    {
        label: '操作',
        slot: 'options'
    }
]

const  circleTypeList = [
    {
        label: "id",
        prop: 'circleTypeId',
        align: 'center',
    },
    {
        label: "车圈类型",
        prop: 'circleTypeName',
        align: 'center',
    },
    {
        label: "状态",
        slot: 'status',
    },
    {
        label: "创建日期",
        slot: 'createTime',
    },
    {
        label: '操作',
        slot: 'options'
    }
]

export default {
    circleList,
    circleTypeList
}