<template>
    <div class="health">
        <div class="search-bar">
            <el-form ref="searchRef" :model="searchForm" label-width="80px" size="small">
                <div>
                    <el-row style="width: 100%;">
                        <el-col :span="6">
                            <el-form-item label="id">
                                <el-input v-model="searchForm.postId" placeholder="请输入id" clearable
                                    @keydown.enter.native="serchData"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="标题">
                                <el-input v-model="searchForm.circleTilte" placeholder="请输入标题" clearable
                                    @keydown.enter.native="serchData"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="车圈名称">
                                <el-input v-model="searchForm.circleName" placeholder="请输入状态" clearable
                                    @keydown.enter.native="serchData"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="状态">
                                <el-input v-model="searchForm.circleStatus" placeholder="请输入状态" clearable
                                    @keydown.enter.native="serchData"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="是否加精">
                                <el-input v-model="searchForm.circleStatus" placeholder="请输入状态" clearable
                                    @keydown.enter.native="serchData"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="7">
                            <el-form-item label="日期">
                                <el-date-picker style="width: 100%" v-model="train_date" type="daterange"
                                    range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
                                    value-format="yyyy-MM-dd" :picker-options="setDateRange" @change="onRefundDateChange">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6" style="float: right;">
                            <div style="float: right;">
                                <el-button type="primary" size="small" @click="serchData">搜索</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-form>
        </div>
        <base-table :columns="postList" :data="tableData" :pagination="searchForm" :total="total" @getData="initData"
            @changeCurrent="selectChange" :loading="loading" :isPaginationShow="true" wrapperHeight="calc(100% - 45px)">
            <el-table-column slot="circleDes" label="内容" align="center" width="120">
                <template slot-scope="scope">
                    <span>
                        {{ scope.row.circleDes.length > 7 ? scope.row.circleDes.substring(0, 7) : scope.row.circleDes
                        }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column slot="isRefinement" label="加精" align="center" width="120">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.isRefinement" :active-value="1" :inactive-value="0" @change="refinementChange(scope.row)">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column slot="status" label="状态" align="center" width="120">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0" @change="statusChange(scope.row)">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column slot="createTime" label="日期" align="center" width="280">
                <template slot-scope="scope">
                    <div>{{ convertGMTToLocal(scope.row.createTime) }}</div>
                </template>
            </el-table-column>
            <el-table-column slot="options" label="操作" align="center" width="120">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="toEditInfo(scope.row)">
                        详情
                    </el-button>
                </template>
            </el-table-column>
        </base-table>
    </div>
</template>
<script>
import columns from "./columns";
import { queryPostList,modifyPost } from '@/api/car'
export default {

    data() {
        return {
            ...columns,
            searchForm: {
                page: 1,
                pageCount: 10,
            },
            tableData: [],
            total: 0,
            loading: false,
            train_date: ''
        }
    },
    mounted() {
        this.initData()
    },
    methods: {
        async serchData() {
            const { code, data } = await queryPostList(this.searchForm)
            if (code == 200) {
                this.tableData = data.dataList
                this.total = data.count
            }
        },
        initData() {
            this.serchData()
        },
        toEditInfo() {

        },
        async statusChange(e) {
            const { code } = await modifyPost(e)
             if (code != 200) {
                e.isRefinement = 0
                this.$message.error('加精失败');
             }
        },
        selectChange() {

        },
        onRefundDateChange() {

        },
        setDateRange() {

        },
        convertGMTToLocal(gmt) {
            const gmtDate = new Date(gmt)
            const bjOffset = 8 * 60
            const bjDate = new Date(gmtDate.getTime() + bjOffset * 60 * 1000)
            return bjDate.toISOString().split(',')[0].replace('T', ' ').replace('.000Z', '');
        },
        async refinementChange(e) {
             const { code } = await modifyPost(e)
             if (code != 200) {
                e.isRefinement = 0
                this.$message.error('加精失败');
             }
        }
    }

}

</script>