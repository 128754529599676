<template>
  <div>
    <el-dialog :title="isEdit ? '修改消息' : '新建消息'" width="770px" :close-on-click-modal="false"
      :visible.sync="dialogFormVisible" custom-class="customW" :before-close="handleClose">
      <el-form ref="systemForm" :model="systemForm" label-width="200px" size="small" :rules="rules">
        <el-form-item label="消息内容" label-width="100px" prop="messageContent" style="margin-bottom: 15px">
          <el-input v-model="systemForm.messageContent" type="textarea" :row="2" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="消息类型" label-width="100px" prop="messageType" style="margin-bottom: 15px">
          <el-select v-model="systemForm.messageType" placeholder="请选择">
            <el-option v-for="item in circleType" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="systemForm.messageType == 1" label="发送人员" label-width="100px" prop="userIds"
          style="margin-bottom: 15px">
          <el-select v-model="value" multiple filterable remote reserve-keyword placeholder="请输入关键词"
            :remote-method="remoteMethod" :loading="loading">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">取 消</el-button>
        <el-button type="primary" @click="confirm" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CONFIG from '../../utils/config'
import { createMessage, editMessage, queryUserByName } from '@/api/car'
export default {
  name: "operating-record",
  mounted() {
    this.url = (CONFIG.ENV_TEST ? CONFIG.BASE_URL_OFF : CONFIG.BASE_URL) + '/api/uploadIcon'
  },
  data() {
    return {
      isEdit: false,
      dialogFormVisible: false,
      systemForm: {
        messageType: 0,
        messageContent: "",
        userIds: "0"
      },
      circleType: [
        {
          label: '全部',
          value: 0
        },
        {
          label: '个人',
          value: 1
        }
      ],
      value: [],
      url: '',
      header: { 'token': localStorage.getItem('eg_group_token') },
      rules: {
        messageContent: [
          { required: true, message: "请输入消息内容", trigger: "change" },
        ],

      },
      options: [],
      loading: false
    };
  },
  methods: {
    async isShow(type, info) {
      this.dialogFormVisible = true;
      if (type == "edit") {
        this.isEdit = true;
        this.systemForm.circleId = info.circleId ? info.circleId : ''
        this.systemForm.messageType = info.messageType ? info.messageType : ''
        this.systemForm.messageContent = info.messageContent ? info.messageContent : ''
      } else {
        this.isEdit = false;
      }
    },

    handleClose(done) {
      done();
      this.close();
    },
    close() {
      this.dialogFormVisible = false;
      this.$refs.systemForm.resetFields();
      this.systemForm.fileList = []
    },
    getFile(file) {
      console.log(file)
    },
    confirm() {
      this.$refs['systemForm'].validate(async (valid) => {
        if (valid) {
          if (this.systemForm.messageType == 1) {
            if (this.value.length > 0) {
              this.systemForm.userIds = this.value.join(',')
            } else {
              this.$message.error('必须指定发送用户');
            }
          }
          let res = this.isEdit ? await editMessage(this.systemForm) : await createMessage(this.systemForm)
          if (res.code == 200) {
            this.close()
            this.$parent.initData()
          }
        }
      });
    },
    selectOnchange(e) {
      console.log(e)
    },
    handSuccess(e) {
      if (e.code == 200) {
        this.systemForm.circleIcon = e.data.icon
      }
    },
    async remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        const { code, data } = await queryUserByName({
          nickName: query
        })
        if (code == 200) {
          this.options = data.map(item => {
            return { value: item.id, label: item.nickName };
          })
          this.loading = false;
        }
      } else {
        this.options = [];
      }
    }
  },
};
</script>
<style lang="scss" scoped>
/deep/ .customW {
  border-radius: 10px !important;

  .el-dialog__header {
    border-bottom: 1px solid #e8eaec;

    .el-dialog__title {
      font-size: 14px;
      color: #17233d;
      font-weight: 700;
    }
  }

  .classification {
    margin: 10px 40px;

    .content-form {
      margin: 0px 10px;
    }
  }

  .el-dialog__footer {
    border-top: 1px solid #e8eaec;
  }
}
</style>